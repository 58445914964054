import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout/layout"
import Banner from "../components/Banner/Banner"
import Button from "../components/Button/Button"
import CTA from "../components/CTA/CTA"
import Quote from "../components/Quote/Quote"
import TxtImg from "../components/TxtImg/TxtImg"
import Underline from "../components/Underline/Underline"
import AboutStyles from "./about.module.css"
import MetaImage from "../images/paula-1.jpeg"

export default function About({ data }) {
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>About | Paula Abbey Counselling & Psychotherapy</title>
                <meta name="title" content="About | Paula Abbey Counselling & Psychotherapy" />
                <meta name="description" content="People-centred counselling from a respectful & supportive therapist. Whatever your reason for seeking counselling, you will be valued, accepted, and respected in the choices you make." />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://paulaabbey.co.uk/about/" />
                <meta property="og:title" content="About | Paula Abbey Counselling & Psychotherapy" />
                <meta property="og:description" content="People-centred counselling from a respectful & supportive therapist. Whatever your reason for seeking counselling, you will be valued, accepted, and respected in the choices you make." />
                <meta property="og:image" content={MetaImage} />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:url" content="https://paulaabbey.co.uk/about/" />
                <meta name="twitter:title" content="About | Paula Abbey Counselling & Psychotherapy" />
                <meta name="twitter:description" content="People-centred counselling from a respectful & supportive therapist. Whatever your reason for seeking counselling, you will be valued, accepted, and respected in the choices you make." />
                <meta name="twitter:image" content={MetaImage} />
            </Helmet>
            <Banner image={data.background.childImageSharp.fluid}>
                <h1>A safe and confidential space for you.</h1>
            </Banner>
            <section>
                <TxtImg split="half" imgFirst={false} imgSrc={data.headshot.childImageSharp.fluid} imgAlt="Paula Abbey">
                    <Underline>
                        <h2>About me</h2>
                    </Underline>
                    <p>I take a person-centred approach to counselling, as I believe that you have the resources within yourself to grow and change. I provide a safe and confidential space for you to reflect and explore your world, without judgement or being told what to do. Instead, I will support you in your exploration so that you may find your own meaning and a way forward that is right for you. Person-centred counselling is an empowering experience of personal discovery, which provides the tools needed for continued growth long after therapy has ended.</p>
                    <p>Whatever your reason for seeking counselling, you will be valued, accepted, and respected in the choices you make, regardless of your race, ethnicity, faith, disability, gender or sexuality.</p>
                </TxtImg>
            </section>
            <section>
                <Quote quote="The curious paradox is that when I accept myself just as I am, then I can change." attribution="Carl Rogers" />
            </section>
            <section className={AboutStyles.text}>
                <Underline>
                    <h2>Training & Memberships</h2>
                </Underline>
                <ul>
                    <li>I hold an MA in Clinical Counselling (Distinction), from the University of Chester.</li>
                    <li>Master Practitioner in Eating Disorders and Obesity, having trained at The National Centre for Eating Disorders.</li>
                    <li>I am a registered member of the <a href="https://www.bacp.co.uk/" target="_blank" rel="noreferrer noopener">British Association for Counselling and Psychotherapy</a> (BACP) and I adhere to their Ethical Framework for Counselling Professions.</li>
                    <li>I am registered with <a href="https://acto-org.uk/" target="_blank" rel="noreferrer noopener">The Association for Counselling and Therapy Online</a> (ACTO).</li>
                    <li>I am fully insured, hold an enhanced DBS check, and registered with the <a href="https://ico.org.uk/" target="_blank" rel="noreferrer noopener">Information Commissioner’s Office</a> to safeguard your data.</li>
                </ul>
            </section>
            <section className={AboutStyles.cta}>
                <CTA>
                    <h2>Make your appointment, today.</h2>
                    <Button type="internal" colour="primary" to="/appointments/">Get in touch</Button>
                </CTA>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query {
        headshot: file(relativePath: {eq: "images/paula-1.jpeg"}) {
            childImageSharp {
                fluid(quality: 72) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        background: file(relativePath: {eq: "images/bench-under-tree.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 2000, quality: 72) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`