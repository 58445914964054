import React from "react"
import PropTypes from "prop-types"
import * as QuoteStyles from "./Quote.module.css"

function Quote(props) {
    return (
        <div className={QuoteStyles.container}>
            <blockquote>
                <p className={QuoteStyles.quote}>"{props.quote}"</p>
            </blockquote>
            <p className={QuoteStyles.attribution}>&mdash; {props.attribution}</p>
        </div>
    )
}

Quote.propTypes = {
    quote: PropTypes.string.isRequired,
    attribution: PropTypes.string.isRequired,
}

export default Quote